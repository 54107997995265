<template>
  <FormKit
    type="form"
    id="theFormId"
    :value="currentDocument"
    v-model="currentDocument"
    @submit="handleSubmit"
    :actions="false"
    autocomplete="off"
  >
    <template #default="{ state: formState }">
      <div class="pb-5 sm:flex-1 sm:overflow-auto" v-if="currentDocument">
        <FormSection>
          <template #sectionName>New {{ entityDocumentConfigStore.documentLabelPlural }}</template>
          <template #default>
            <div class="mt-8 first-of-type:mt-0">
              <div class="rounded-lg border border-neutral-300" v-if="currentDocument.documents?.length">
                <template v-for="(doc, docIndex) in currentDocument.documents" :key="doc.id">
                  <div
                    class="mb-2 flex items-center justify-between bg-neutral-200 py-1.5 pl-2.5 pr-2 md:mb-0 md:bg-neutral-100"
                    :class="{ 'rounded-t-lg': docIndex === 0 }"
                  >
                    <div class="flex items-center">
                      <NumberBadge
                        v-if="(currentDocument.documents?.length ?? 0) > 1"
                        class="flex-none mr-3"
                        :number="docIndex + 1"
                      ></NumberBadge>
                      <span>{{ entityDocumentConfigStore.documentLabel }}</span>
                    </div>
                    <div class="flex items-center pr-2">
                      <BaseButton
                        class="ml-5 h-8 w-8 px-1"
                        icon
                        borderless
                        variant="danger-subtle"
                        label="Remove Document"
                        @click="removeSection(doc.id)"
                        :disabled="currentDocument.documents?.length === 1"
                      >
                        <template #iconBefore><XMarkIcon class="w-5" /></template
                      ></BaseButton>
                    </div>
                  </div>
                  <div class="px-2 pb-4 pt-4 lg:px-4 lg:pb-1">
                    <EntityDocumentLookupFields
                      :category-murn="categoryMurn"
                      :group-name="'docInfo' + doc.id"
                      :filters="onFormFilter"
                      :off-form-filters="filters"
                      :doc-obj="doc"
                    ></EntityDocumentLookupFields>
                  </div>
                </template>
              </div>
            </div>
            <div
              class="flex flex-wrap items-center justify-items-center rounded-lg border-2 border-dashed border-neutral-300 p-4 lg:flex-nowrap lg:gap-4"
              :class="{ 'mt-8': currentDocument.documents?.length }"
            >
              <input
                ref="fileInput"
                type="file"
                @change="fileSelected($event as unknown as Event)"
                class="hidden"
                multiple
                :accept="bulkAccept"
              />
              <SkeletonButton v-if="bulkExtensionsLoading" class="mt-2 lg:mt-0 w-full"></SkeletonButton>
              <BaseButton
                v-else
                class="mt-2 lg:mt-0 w-full"
                variant="create-hollow"
                label="Add Files"
                @click="triggerHiddenFileInput"
              >
                <template #iconBefore><PlusIcon /></template
              ></BaseButton>
            </div>
          </template>
        </FormSection>
      </div>
      <FormActions
        :save-action="saveAction"
        :cancel-action="cancelAction"
        :form-state="formState"
        :manual-disable="isInvalid || (currentDocument?.documents && currentDocument?.documents?.length < 1)"
      ></FormActions>
    </template>
  </FormKit>
</template>

<script lang="ts">
import NumberBadge from "@/components/badge/NumberBadge.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import RemoveButton from "@/components/button/RemoveButton.vue";
import SkeletonButton from "@/components/common/SkeletonButton.vue";
import type { Document, DocumentLoadingState, DocumentNew } from "@/components/entity/document";
import { createDocuments, getDocuments, getNewDocument } from "@/components/entity/document/entityDocumentApi";
import { useEntityDocumentConfigStore } from "@/components/entity/document/entityDocumentConfigStore";
import EntityDocumentLookupFields from "@/components/entity/document/EntityDocumentLookupFields.vue";
import { getBulkExtensions, setIsInvalid } from "@/components/entity/document/helper";
import injectionSymbols from "@/components/entity/document/injectionSymbols";
import { default as FormActions } from "@/components/form/FormActions.vue";
import { default as FormSection } from "@/components/form/FormSection.vue";
import { LookupFiltersProp } from "@/components/lookup";
import LookupDropdown from "@/components/lookup/LookupDropdown.vue";
import { Paging, useApi } from "@/utils/api";
import { childRouteCancelAction, getUniqueId } from "@/utils/helpers";
import { FormKitNode, setErrors, submitForm } from "@formkit/core";
import { PlusIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { PropType, computed, defineComponent, onMounted, provide, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

/** This is the  *CREATE ONLY* screen for multiple uploads/documents. Unlike most create screens, it is not shared with edit. It allows the upload of documents and their details. */
export default defineComponent({
  name: "EntityDocumentLookupMultiple",
  emits: [],
  props: {
    /** Murn of the document category */
    categoryMurn: {
      type: String,
      required: true,
    },
    /** Name of the field ID (entityId) that the document belongs to */
    idField: {
      type: String,
      required: true,
    },
    /** Name of the route for the document list page for this entity */
    listName: {
      type: String,
      required: true,
    },
    /** Object containing additionalIds and their values that the document should be tied to (E.g. {exampleEntityId: 3}) */
    additionalIds: {
      type: Object,
      default: () => {},
    },
    /** Filters enable you to "cascade" dropdowns or to perform additional filtering based on Lookup metadata. This is the primary filters prop and will be used most of the time. */
    filters: {
      type: Object as PropType<LookupFiltersProp>,
    },
    /** Allow the component to automatically filter document type by the document category supplied. Default to true. */
    filterByCategoryMurn: {
      type: Boolean,
      default: true,
    },
    /** Allow the component to automatically filter document types by the collection limits in their metadata. This only looks at the other docs on the form, and not the rest of the documents on the entity. To include those, please add them to the 'filters' prop. Default to true. */
    filterByCollectionLimit: {
      type: Boolean,
      default: true,
    },
    /** Component will automatically grab the list of document types from the entity to include them in the collection limit. To disable that, set this prop to true. */
    disableAutomaticDocumentTypeFetch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const entityDocumentConfigStore = useEntityDocumentConfigStore();
    const route = useRoute();
    const router = useRouter();

    const bulkExtensionsLoading = ref(false);
    const occupiedDocumentTypes = ref<string[]>([]);
    const bulkAccept = ref(); //value of all extensions allowed for all document types under the given category murn

    const { exec: fetchDocuments } = useApi<Paging<Document>>(getDocuments, {
      responseAdapter: (response) => {
        return response.data;
      },
    });

    /*
      Always fetch the entity data again in an Edit component.
      (All others should inject what's provided by the *Base.vue component)
      This reduces the probability of running into a handful of issues.
    */
    const { dataRef: currentDocument, exec: fetchDocument } = useApi<DocumentNew>(getNewDocument, {
      responseAdapter: (response) => {
        if (response.data.fileName) {
          response.data.file = [{ name: response.data.fileName }];
        }
        response.data.documents = [];

        return response.data;
      },
    });

    const addSection = () => {
      currentDocument.value?.documents?.push({
        id: getUniqueId("document"),
      });
    };

    const removeSection = (id: string | number) => {
      if (currentDocument.value)
        currentDocument.value.documents = currentDocument.value?.documents?.filter(
          (section: Document) => section.id !== id,
        );
    };

    watch(
      () => route.params.documentId,
      (newValue, oldValue) => {
        fetchDocument({ [props.idField]: parseInt(route.params.id as string) });
        bulkExtensionsLoading.value = true;
        getBulkExtensions(props.categoryMurn).then((result) => {
          // setTimeout(() => {
          bulkAccept.value = result;
          bulkExtensionsLoading.value = false;
          // }, 4000);
        });

        if (!props.disableAutomaticDocumentTypeFetch) {
          const result: string[] = [];
          //TODO: Better way to do this: Take 500 because we want all documents - may need to increase
          fetchDocuments({ [props.idField]: route.params.id, take: 500 }).then((docs) => {
            docs?._items.forEach((doc) => {
              result.push(doc.typeMurn);
            });
            occupiedDocumentTypes.value = result;
          });
        }
      },
      { immediate: true },
    );

    const getParams = () => {
      const { documentId, ...rest } = route.params;
      return rest;
    };

    const cancelAction = function () {
      childRouteCancelAction({ router, routeName: props.listName, params: getParams() });
    };

    const { exec: execSaveDocument } = useApi(createDocuments, {
      responseAdapter: (response) => {
        cancelAction();
        return response.data;
      },
    });

    const handleSubmit = async (documents: Document) => {
      //We need to convert the form data to the shape the BE expects.
      //The EntityDocumentLookupFields creates groups.. we also have this list of documents. This is duplicative.
      const docRequests = [];
      const baseDoc = { ...documents };
      delete baseDoc.documents;
      for (const [key, value] of Object.entries(baseDoc)) {
        if (key.startsWith("docInfo")) {
          delete baseDoc[key];
        }
      }
      for (const [key, value] of Object.entries(documents)) {
        if (key.startsWith("docInfo")) {
          docRequests.push({ ...baseDoc, ...documents[key] });
        }
      }
      delete documents.documents;

      const saveDocRequest = { ...baseDoc, documentRequests: docRequests };

      try {
        //  console.warn("going to save", saveDocRequest);
        await execSaveDocument(saveDocRequest);
      } catch (errors) {
        setErrors(
          "theFormId",
          ["There was an error saving; please try again."], // (optional) An array of form level errors
          //inputErrors // (optional) input level errors
        );
        return; // We can't re-throw, FormKit needs us to resolve.
      }
    };

    const saveAction = function () {
      submitForm("theFormId");
    };

    const fileInput = ref<HTMLInputElement | null>(null);

    const fileSelected = ($event: Event) => {
      //this is giving the selected file(s) to the EntityDocumentLookupFields component
      const input = $event.target as HTMLInputElement;
      if (!input.files) return;
      for (let i = 0; i < input.files?.length; i++) {
        let name = input.files[i].name;
        const dot = input.files[i].name.lastIndexOf(".");
        if (dot > -1) {
          name = name.substring(0, dot);
        }

        //We need to add it to the documents list so the for loop recognizes it
        const newId = getUniqueId("document");
        const docName = "docInfo" + newId;
        currentDocument.value?.documents?.push({ id: newId });

        //then we need to add it to form group it belongs to so it will actually populate the fields
        const newDoc = {
          file: [{ name: input.files[i].name, file: input.files[i] }],
          displayName: name,
        };
        if (currentDocument.value) currentDocument.value[docName] = newDoc;
      }

      if (fileInput.value) fileInput.value.value = "";
    };

    const triggerHiddenFileInput = () => {
      fileInput.value?.click();
    };

    //starting out with blank filter in case collectionLimit and category filters are not wanted
    const onFormFilter: LookupFiltersProp = ref({});

    //add collection limit filter (creates references to the typeMurn inputs on the form)
    if (props.filterByCollectionLimit) {
      onFormFilter.value["collectionLimit"] = computed(() => {
        const result = [];
        if (currentDocument.value)
          for (const [key, value] of Object.entries(currentDocument.value)) {
            if (key.startsWith("docInfo")) {
              result.push(currentDocument.value?.[key].typeMurn);
            }
          }

        if (!props.disableAutomaticDocumentTypeFetch) {
          return [...result, ...occupiedDocumentTypes.value];
        }
        return result;
      });
    }

    //add category filter
    if (props.filterByCategoryMurn) {
      onFormFilter.value["category"] = props.categoryMurn;
    }

    //get the state of the upload from the child component
    const isInvalid = ref(false);

    const documentSectionStates = new Map();

    const registerAndUpdateDocumentSectionStates = (sectionName: string, states: DocumentLoadingState) => {
      documentSectionStates.set(sectionName, states);
      isInvalid.value = setIsInvalid(documentSectionStates);
    };

    provide(injectionSymbols.UploadInProgressKey, { registerAndUpdateDocumentSectionStates });

    return {
      cancelAction,
      saveAction,
      handleSubmit,
      currentDocument,
      // isNew,
      entityDocumentConfigStore,
      addSection,
      removeSection,
      fileSelected,
      fileInput,
      triggerHiddenFileInput,
      bulkAccept,
      onFormFilter,
      isInvalid,
      bulkExtensionsLoading,
    };
  },
  components: {
    XMarkIcon,
    NumberBadge,
    FormActions,
    FormSection,
    LookupDropdown,
    EntityDocumentLookupFields,
    BaseButton,
    RemoveButton,
    PlusIcon,
  },
});
</script>
