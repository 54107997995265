import { useAppSettingsStore } from "@/components/common/appSettingsStore.js";
import type { notifyErrorCb, notifySuccessCb } from "@/components/notifications/notifications.js";
import apiService from "@/utils/api/apiService.js";
import { useUser } from "@/utils/auth/authentication/useUser.js";
import {
  default as authorizationClientConfig,
  AuthorizationClientConfig,
} from "@/utils/auth/authorization/AuthorizationClientConfig.js";
import { AUTH_REQUEST_PREEMPTED, AUTHZ_KEY } from "@/utils/auth/authorization/constants.js";
import { handlePreempts } from "@/utils/auth/authorization/handlePreempts.js";
import { getHash } from "@/utils/helpers/getHash.js";
import { toRaw, unref } from "vue";
import { Router } from "vue-router";

/**
 * Used by AuthorizationClient to issue Authorization Requests
 * It's critical that the shape of this class matches the props on the Authorize component.
 * This allows you to v-bind an AuthRequest on an Authorize component
 * @typedef
 */
export class AuthRequest {
  /**
   * Used to uniquely identify a Request. You can change parameters on the Request while keeping the same key and only the most recent result will be returned.
   * @type {number} */
  requestKey: number;
  permissionName?: string;
  policyName?: string;
  groupName?: string;
  roleName?: string;
  /**
   * Make sure your resource has NO circular dependencies or the request will fail
   * @type {Object} */
  resource: any;
  resourceName?: string;
  resourceId?: string;
  systemIdentifier?: string;

  correlationId?: string;
  secondaryIdentifier?: any;

  identityClaims?: any; // TODO: This shouldn't be any, but I don't know what shape it is.

  constructor(requestKey?: number) {
    const [randomNumber] = window.crypto.getRandomValues(new Uint32Array(1));
    this.requestKey = requestKey || randomNumber;
  }

  copyForThrottling(identityClaims: any, resource: any): AuthRequest {
    const newAr = new AuthRequest(this.requestKey);
    newAr.permissionName = this.permissionName;
    newAr.policyName = this.policyName;
    newAr.groupName = this.groupName;
    newAr.roleName = this.roleName;
    newAr.resource = resource;
    newAr.resourceName = this.resourceName;
    newAr.resourceId = this.resourceId;
    newAr.systemIdentifier = this.systemIdentifier;
    newAr.correlationId = this.correlationId;
    newAr.secondaryIdentifier = this.secondaryIdentifier;
    newAr.identityClaims = identityClaims;
    return newAr;
  }

  /**
   * Returns a boolean indicating whether the AuthRequest has no parameters specified within it
   * @returns {Boolean} False if there are any truthy values assigned to any property. Otherwise true.
   */
  isEmpty() {
    return (
      !this.permissionName &&
      !this.policyName &&
      !this.groupName &&
      !this.roleName &&
      !this.resource &&
      !this.resourceName &&
      !this.resourceId &&
      !this.systemIdentifier
    );
  }
}

const secondaryIdentifier = "UI Client";
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export interface NotifyConfigForAuth {
  successNotifCb?: notifySuccessCb;
  errorNotifCb?: notifyErrorCb;
}

export class AuthorizationClient {
  AUTH_ENTITY_NAME = "Authorization";
  AUTH_GRANT_ENTITY_NAME = "Authorization Grant";
  config: any;
  cache: Map<string, any> = new Map();
  requestRejectors: Map<number, Function> = new Map();
  api;

  constructor(config: AuthorizationClientConfig) {
    this.config = config;
    this.api = apiService(AUTHZ_KEY, config);
  }

  /**
   * Special purpose method for authorizing application navigation. Not intended for side-nav.
   * @async
   * @param {[AuthRequest]} authorizationRequests Array of AuthRequest objects
   * @param {Object} notifConfig Optional notification config override
   * @returns [AuthorzationResponse] Returns an array of AuthorizationReponses
   */
  async authorizeNavigationRequests(authorizationRequests: AuthRequest[], notifConfig: NotifyConfigForAuth = {}) {
    const { userRef } = useUser();
    const appSettingsStore = useAppSettingsStore();

    if (appSettingsStore.bypassAuth) {
      console.warn("UI AUTH IS BEING BYPASSED."); // Console spam is intentional.
      const response = authorizationRequests.map((x) => ({
        isSuccess: true,
        isError: false,
        correlationId: x.correlationId,
      }));
      return response;
    }

    const successNotifCb: notifySuccessCb = (options) => {
      // options.fetched = this.AUTH_ENTITY_NAME;
      options.hide = true;
      notifConfig.successNotifCb && notifConfig.successNotifCb(options);
    };
    const errorNotifCb: notifyErrorCb = (options) => {
      options.fetched = this.AUTH_ENTITY_NAME;
      notifConfig.errorNotifCb && notifConfig.errorNotifCb(options);
    };

    authorizationRequests.forEach((ar, index) => {
      this.addSystemIdentifierToRequestIfMissing(ar);
      ar.identityClaims = userRef.value.localClaims;
    });

    const request = {
      authorizationRequests,
      identityClaims: userRef.value.localClaims,
      systemIdentifier: this.config.defaultSystemIdentifier,
      secondaryIdentifier,
    };
    const authPromise = this.api
      .post(this.config.baseApiUrl + "/authorization", request, {
        authToken: this.config.getAuthToken(),
        successNotifCb,
        errorNotifCb,
      })
      .then((result: any) => result.data.authorizationResponses);

    return authPromise;
  }

  #currentPromise: Promise<any> | null = null;
  #pendingRequests: AuthRequest[] = [];
  /**
   * Combines all authorization requests within a 100ms window into a single request, but returns a promise with results specific to the provided AuthRequest.
   * @async
   * @param {AuthRequest} ar A single AuthRequest
   * @returns Promise(AuthorizationResponse)
   */
  async throttledAuthorize(ar: AuthRequest) {
    //, notifConfig = {}) {
    const { userRef } = useUser();
    const appSettingsStore = useAppSettingsStore();

    // Handle global auth bypass (used when building new features)
    if (appSettingsStore.bypassAuth) {
      console.warn("UI AUTH IS BEING BYPASSED."); // Console spam is intentional.
      const response = { isSuccess: true, requestKey: ar.requestKey };
      return response;
    }

    const authorizationRequest = ar.copyForThrottling(toRaw(userRef.value.localClaims), toRaw(unref(ar.resource)));

    this.addSystemIdentifierToRequestIfMissing(authorizationRequest);
    authorizationRequest.correlationId = this.getCacheKey(authorizationRequest);
    authorizationRequest.secondaryIdentifier = secondaryIdentifier;

    // Return cached item if it exists, no need to queue.
    if (this.cache.has(authorizationRequest.correlationId)) {
      return this.cache.get(authorizationRequest.correlationId);
    }

    if (!this.#currentPromise) {
      const successNotifCb: notifySuccessCb = (options) => {
        options.hide = true;
        // notifConfig.successNotifCb && notifConfig.successNotifCb(options);
      };
      const errorNotifCb: notifyErrorCb = (options) => {
        options.fetched = this.AUTH_ENTITY_NAME;
        // notifConfig.errorNotifCb && notifConfig.errorNotifCb(options);
      };

      this.#currentPromise = wait(100).then(() => {
        // Wrap all of pending requests into a single request
        const request = {
          authorizationRequests: [...this.#pendingRequests],
          secondaryIdentifier,
        };
        this.#currentPromise = null;
        this.#pendingRequests.length = 0;

        const authPromise = this.api.post(this.config.baseApiUrl + "/authorization", request, {
          authToken: this.config.getAuthToken(),
          successNotifCb,
          errorNotifCb,
        });
        // Convert array of responses to a map using correlationId as the key.
        return authPromise.then((result: any) => {
          return new Map(result.data.authorizationResponses.map((response: any) => [response.correlationId, response]));
        });
      });
    }

    this.#pendingRequests.push(authorizationRequest);

    const resultPromise = new Promise((resolve, reject) => {
      // Enables us to supersede a previous AR's results based on the requestKey.
      // This is handy when your initial AR may contain stale data and you instead want the most recent result.
      if (authorizationRequest.requestKey) {
        if (this.requestRejectors.has(authorizationRequest.requestKey)) {
          this.requestRejectors.get(authorizationRequest.requestKey)?.({
            type: AUTH_REQUEST_PREEMPTED,
            requestKey: authorizationRequest.requestKey,
          });
        }
        this.requestRejectors.set(authorizationRequest.requestKey, reject);
      }

      // Return the current promise's results, for the current request's correlationId.
      const result = this.#currentPromise?.then((resultMap) => {
        const innerResult = resultMap.get(authorizationRequest.correlationId);
        resolve(innerResult);
        return innerResult;
      });
      if (authorizationRequest.correlationId) this.cache.set(authorizationRequest.correlationId, result);
    });

    resultPromise.catch(handlePreempts);

    return resultPromise;
  }

  /**
   * Calls the server API to validate and redeem a user action token
   * - Only use this if you want to modify the notifConfig or if you must execute your auth query immediately!
   * @async
   * @param {AuthRequest} authorizationRequest Object representing the authorization request to make
   * @param {Object} notifConfig
   * @returns {authorizationResponse}
   * @todo This falls over if your authorizationReqest includes a resource with circular refs.
   */
  async authorizeSingle(authorizationRequest: AuthRequest, notifConfig?: NotifyConfigForAuth) {
    const { userRef } = useUser();
    const appSettingsStore = useAppSettingsStore();

    if (appSettingsStore.bypassAuth) {
      console.warn("UI AUTH IS BEING BYPASSED."); // Console spam is intentional.
      const response = { isSuccess: true };
      return response;
    }

    if (!notifConfig) {
      throw "Only use authorizeSingle if you want to modify the notifConfig or if you must execute your auth query immediately! It's less efficient than throttledAuthorize";
    }

    this.addSystemIdentifierToRequestIfMissing(authorizationRequest);

    const cacheKey = this.getCacheKey(authorizationRequest);
    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey);
    }

    const successNotifCb: notifySuccessCb = (options) => {
      options.hide = true;
      notifConfig.successNotifCb && notifConfig.successNotifCb(options);
    };
    const errorNotifCb: notifyErrorCb = (options) => {
      options.fetched = this.AUTH_ENTITY_NAME;
      notifConfig.errorNotifCb && notifConfig.errorNotifCb(options);
    };

    const request = {
      ...authorizationRequest,
      resource: unref(authorizationRequest.resource),
      identityClaims: userRef.value.localClaims,
      secondaryIdentifier,
    };

    const resultPromise = new Promise((resolve, reject) => {
      if (authorizationRequest.requestKey) {
        if (this.requestRejectors.has(authorizationRequest.requestKey)) {
          this.requestRejectors.get(authorizationRequest.requestKey)?.({
            type: AUTH_REQUEST_PREEMPTED,
            requestKey: authorizationRequest.requestKey,
          });
        }
        this.requestRejectors.set(authorizationRequest.requestKey, reject);
      }

      const promise = this.api.post(this.config.baseApiUrl + "/authorization", request, {
        authToken: this.config.getAuthToken(),
        successNotifCb,
        errorNotifCb,
      });

      promise.then((result: any) => {
        // Wrap this in a timeout for debugging slow auth.
        // setTimeout(() => {
        resolve(result.data);
        // }, 5000);
      });
      this.cache.set(cacheKey, promise);
    });

    resultPromise.catch(handlePreempts);

    return resultPromise;
  }
  /**
   * @async
   * @param {AuthRequesst} authorizationRequest
   * @returns {Boolean}
   * @todo This falls over if your authorizationReqest includes a resource with circular refs.
   */
  async isAuthorized(authorizationRequest: AuthRequest): Promise<boolean> {
    //}, notifConfig = {}) {
    return this.throttledAuthorize(authorizationRequest).then((response) => {
      return response.isSuccess;
    });
  }

  /**
   * Uses Runs an isAuthorized check on every AuthRequest in the array and returns a promise with an array of isAuthorized responses
   * @async
   * @todo This falls over if your authorizationReqest includes a resource with circular refs.
   */
  async isAuthorizedArray(authorizationRequests: AuthRequest[]) {
    // , notifConfig = {}
    return (await Promise.allSettled(authorizationRequests.map((x) => this.isAuthorized(x)))).map(
      //If the request was rejected for some reason, fall back to denying auth.
      (result) => (result.status === "fulfilled" ? result.value : false),
    );
  }

  async isAuthorizedRedirect(authorizationReqest: AuthRequest, router: Router, routeName = "NotAuthorized") {
    // const hideNotif = (options) => {
    //   options.hide = true;
    // };
    const isAuth = await this.isAuthorized(authorizationReqest);
    // , {
    //   successNotifCb: hideNotif,
    //   errorNotifCb: hideNotif,
    // });
    if (!isAuth) {
      router.replace({ name: routeName });
    } else {
      return true;
    }
  }

  addSystemIdentifierToRequestIfMissing(request: AuthRequest) {
    if (!request.systemIdentifier) {
      request.systemIdentifier = this.config.defaultSystemIdentifier;
    }
  }
  /**
   * Calls the server API to create authorization grants
   */
  createAuthorizationGrant(createAuthorizationGrantRequest: any, notifConfig: NotifyConfigForAuth = {}) {
    const successNotifCb: notifySuccessCb = (options) => {
      options.created = this.AUTH_GRANT_ENTITY_NAME;
      notifConfig.successNotifCb && notifConfig.successNotifCb(options);
    };
    const errorNotifCb: notifyErrorCb = (options) => {
      options.created = this.AUTH_GRANT_ENTITY_NAME;
      notifConfig.errorNotifCb && notifConfig.errorNotifCb(options);
    };

    return this.api.post(this.config.apiBaseUrl + "/authorization-grants", createAuthorizationGrantRequest, {
      authToken: this.config.getAuthToken(),
      successNotifCb,
      errorNotifCb,
    });
  }
  deleteAuthorizationGrant(deleteAuthorizationGrantRequest: any, notifConfig: NotifyConfigForAuth = {}) {
    const successNotifCb: notifySuccessCb = (options) => {
      options.deleted = this.AUTH_GRANT_ENTITY_NAME;
      notifConfig.successNotifCb && notifConfig.successNotifCb(options);
    };
    const errorNotifCb: notifyErrorCb = (options) => {
      options.deleted = this.AUTH_GRANT_ENTITY_NAME;
      notifConfig.errorNotifCb && notifConfig.errorNotifCb(options);
    };

    return this.api.delete(this.config.apiBaseUrl + "/authorization-grants", {
      ...deleteAuthorizationGrantRequest,
      authToken: this.config.getAuthToken(),
      successNotifCb,
      errorNotifCb,
    });
  }
  /**
   * Converts an AuthRequest object to a string usable in a Map.
   * @async
   * @param {AuthRequest} authorizationRequest Object representing the authorization request to convert
   * @returns {String} A unique hash of the authorizationRequest
   */
  getCacheKey(authorizationRequest: AuthRequest) {
    // This falls over if your authorizationReqest includes a resource with circular refs.
    return getHash(
      JSON.stringify({
        ...authorizationRequest,
        resource: unref(authorizationRequest.resource),
        requestKey: null,
        correlationId: null,
      }),
    ).toString();
  }
}

export default new AuthorizationClient(authorizationClientConfig);
