<template>
  <BaseButton
    class="mt-0.5 mb-0.5 h-8 w-full md:w-8 flex-none px-1"
    icon
    borderless
    variant="plain-subtle"
    :label="label"
    @click="$emit('clickAction')"
  >
    <template #iconBefore>
      <!-- Option one - dynamic imports  -->
      <component :is="cmpActiveIcon" class="w-5" v-if="activeCondition && optionOne" /><component
        :is="cmpInactiveIcon"
        class="w-5"
        v-if="!activeCondition && optionOne"
      />
      <!-- Option two - predicted/assumed imports  -->
      <component :is="activeIcon" class="w-5" v-if="activeCondition && !optionOne" /><component
        :is="inactiveIcon"
        class="w-5"
        v-if="!activeCondition && !optionOne"
      />
    </template>
  </BaseButton>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent } from "vue";
import { BaseButton } from "@/components/button";
/** Component to allow the use of the flag icon in schema.  */
export default defineComponent({
  name: "SquareButton",
  emits: ["clickAction"],
  props: {
    label: {
      type: String,
      required: true,
    },
    activeCondition: {
      type: Boolean,
      required: true,
    },
    activeIcon: {
      type: String,
      required: true,
    },
    inactiveIcon: {
      type: String,
      required: true,
    },
    //just for demo of options - will be removed once decided
    optionOne: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    // function getDynamicIcon(iconName: string) {
    //   return defineAsyncComponent(() => import(`@heroicons/vue/24/solid/${iconName}`));
    // }

    const iconMap: any = {
      FlagIcon: () => import("@heroicons/vue/24/solid/FlagIcon"),
      FlagIconOutline: () => import("@heroicons/vue/24/outline/FlagIcon"),
      EyeIcon: () => import("@heroicons/vue/24/solid/EyeIcon"),
      EyeSlashIcon: () => import("@heroicons/vue/24/solid/EyeSlashIcon"),
    };

    function getDynamicIcon(iconName: string) {
      const importFunction = iconMap[iconName];
      if (!importFunction) {
        throw new Error(`Icon ${iconName} not found`);
      }
      return defineAsyncComponent(importFunction);
    }

    const cmpActiveIcon = computed(() => getDynamicIcon(props.activeIcon));
    const cmpInactiveIcon = computed(() => getDynamicIcon(props.inactiveIcon));

    return {
      cmpActiveIcon,
      cmpInactiveIcon,
    };
  },
  components: {
    BaseButton,
  },
});
</script>
