<template>
  <div class="bg-white flex-1 flex flex-col w-full" :class="viewerStore.showSidebar ? 'visible' : 'invisible'">
    <!-- header -->
    <div class="flex flex-none bg-neutral-100">
      <div class="flex-none">
        <Menu as="div" class="relative lg:hidden" v-slot="{ open }">
          <MenuButton
            class="flex rounded-md ml-1 mt-0.5 p-1 text-sm text-neutral-400 transition-colors duration-150 ease-in focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-0"
            :class="[open ? `text-brand-500` : `hover:text-neutral-500`]"
          >
            <span class="sr-only">Open viewer menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </MenuButton>
          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute -ml-1 left-0 z-20 -mt-8 w-[90svw] origin-top-left divide-y divide-neutral-100 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem as="div" v-slot="{ active }" class="mb-2">
                  <div class="px-3" :class="[active ? 'bg-neutral-100' : '']">
                    <span class="sr-only">Close viewer menu</span>
                    <XMarkIcon class="block h-6 w-6 text-neutral-500" aria-hidden="true" />
                  </div>
                </MenuItem>
                <MenuItem as="div" v-slot="{ active }">
                  <button
                    class="inline-flex w-full px-4 py-2 text-left text-sm text-neutral-700"
                    :class="[active ? 'bg-neutral-100' : '']"
                    @click="viewList"
                  >
                    <MagnifyingGlassIcon class="block h-5 w-5 mr-2" aria-hidden="true" />
                    Search
                  </button>
                </MenuItem>
                <MenuItem as="div" v-slot="{ active }">
                  <button
                    class="inline-flex w-full px-4 py-2 text-left text-sm text-neutral-700"
                    :class="[active ? 'bg-neutral-100' : '']"
                    @click="viewDatasets"
                  >
                    <FunnelIcon class="block h-5 w-5 mr-2" aria-hidden="true" />
                    Datasets and Filters
                  </button>
                </MenuItem>
                <MenuItem as="div" v-slot="{ active }">
                  <button
                    class="inline-flex w-full px-4 py-2 text-left text-sm text-neutral-700"
                    :class="[active ? 'bg-neutral-100' : '']"
                    @click="viewLayers"
                  >
                    <Square3Stack3DIcon class="block h-5 w-5 mr-2" aria-hidden="true" />
                    Layers
                  </button>
                </MenuItem>
                <MenuItem as="div" v-slot="{ active }">
                  <button
                    class="inline-flex w-full px-4 py-2 text-left text-sm text-neutral-700"
                    :class="[active ? 'bg-neutral-100' : '']"
                    @click="viewHelp"
                  >
                    <QuestionMarkCircleIcon class="block h-5 w-5 mr-2" aria-hidden="true" />
                    Help
                  </button>
                </MenuItem>

                <MenuItem as="div" v-slot="{ active }" class="mt-3">
                  <button
                    class="inline-flex w-full px-4 py-2 text-left text-sm text-neutral-700"
                    :class="[active ? 'bg-neutral-100' : '']"
                  >
                    <ArrowUturnLeftIcon class="block h-5 w-5 mr-2" aria-hidden="true" />
                    Reset
                  </button>
                </MenuItem>

                <!-- layers -->
                <!-- help -->
                <!-- reset -->
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <div class="flex-auto min-h-9 px-2 py-1">
        <div class="flex items-center justify-center my-auto">
          <h3 class="font-medium text-lg lg:text-base text-center">
            {{ currentViewTitle
            }}<span class="text-neutral-500 pl-2" v-if="viewerStore.currentSidebarPanel === 'list'">{{
              resultsCount
            }}</span>
          </h3>
        </div>
        <ViewerKeywordFilter v-show="showKeywords" />
      </div>
      <div class="flex-none">
        <button class="p-1 mt-1 mr-1 text-neutral-400 lg:hidden" @click="viewerStore.sidebarHide()">
          <span class="sr-only">Return to map</span><XMarkIcon class="block h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>

    <ViewerPanelList v-show="viewerStore.currentSidebarPanel === 'list'" />
    <ViewerPanelDatasets v-show="viewerStore.currentSidebarPanel === 'datasets'" />
    <ViewerPanelLayers v-show="viewerStore.currentSidebarPanel === 'layers'" />
    <ViewerPanelHelp v-show="viewerStore.currentSidebarPanel === 'help'" />
  </div>
  <div
    class="right-0 translate-x-full absolute top-1/3 -translate-y-1/2 bg-white py-1.5 pr-1.5 pl-1 rounded-r-md text-neutral-400 drop-shadow-lg z-10"
  >
    <ViewerPanelButton
      :active="false"
      :label="viewerStore.showSidebar ? 'Close Sidebar' : 'Open Sidebar'"
      @click="toggleSidebar"
      :class="[
        'transition-all mb-3 bg-neutral-200 text-neutral-500 group',
        viewerStore.showSidebar ? '' : 'rotate-180',
      ]"
      ><template #icon><ChevronLeftIcon class="w-5 h-5 group-hover:-translate-x-0.5" /></template
    ></ViewerPanelButton>
    <div class="space-y-1">
      <ViewerPanelButton
        :active="viewerStore.currentSidebarPanel === 'list'"
        :icon="MapPinIcon"
        label="Locations"
        @click="viewList"
      />
      <ViewerPanelButton
        :active="viewerStore.currentSidebarPanel === 'datasets'"
        :icon="FunnelIcon"
        label="Datasets and Filters"
        @click="viewDatasets"
      />
      <ViewerPanelButton
        :active="viewerStore.currentSidebarPanel === 'layers'"
        :icon="Square3Stack3DIcon"
        label="Layers"
        @click="viewLayers"
      />
      <ViewerPanelButton
        :active="viewerStore.currentSidebarPanel === 'help'"
        :icon="QuestionMarkCircleIcon"
        label="Help"
        @click="viewHelp"
      />
    </div>
    <ViewerPanelButton class="mt-3" :active="false" :icon="ArrowUturnLeftIcon" label="Reset" @click="reset" />
  </div>
</template>

<script lang="ts">
import { useAppSettingsStore } from "@/components/common/appSettingsStore.js";
import type { NotifyOptions } from "@/components/notifications/NotifyOptions.js";
import { useNotifier } from "@/components/notifications/useNotifier.js";
import { ViewerListProps } from "@/components/viewer";
import ViewerKeywordFilter from "@/components/viewer/ViewerKeywordFilter.vue";
import ViewerPanelButton from "@/components/viewer/ViewerPanelButton.vue";
import ViewerPanelDatasets from "@/components/viewer/ViewerPanelDatasets.vue";
import ViewerPanelHelp from "@/components/viewer/ViewerPanelHelp.vue";
import ViewerPanelLayers from "@/components/viewer/ViewerPanelLayers.vue";
import ViewerPanelList from "@/components/viewer/ViewerPanelList.vue";
import injectionSymbols from "@/components/viewer/injectionSymbols";
import { useViewerStore } from "@/components/viewer/viewerStore";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  Square3Stack3DIcon,
} from "@heroicons/vue/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/solid";
import { computed, defineComponent, inject, ref, shallowRef } from "vue";
import { useRoute, useRouter } from "vue-router";

/**
 *  Component for rendering Viewer Panels, such as a list of results, data filters, layers, and help.
 */
export default defineComponent({
  name: "ViewerSidebar",
  components: {
    Bars3Icon,
    XMarkIcon,
    ArrowUturnLeftIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    MagnifyingGlassIcon,
    FunnelIcon,
    QuestionMarkCircleIcon,
    Square3Stack3DIcon,
    MapPinIcon,
    MenuButton,
    Menu,
    MenuItem,
    MenuItems,
    ViewerPanelButton,
    ViewerKeywordFilter,
    ViewerPanelList,
    ViewerPanelLayers,
    ViewerPanelDatasets,
    ViewerPanelHelp,
  },
  setup(props, context) {
    const viewerStore = useViewerStore();
    const appSettingsStore = useAppSettingsStore();
    const { notifyInfo } = useNotifier();

    const showKeywords = ref(false);
    const currentViewTitle = ref("list");

    const viewList = () => {
      currentViewTitle.value = "Locations";
      showKeywords.value = true;
      viewerStore.currentSidebarPanel = "list"; // list, datasets, layers, help
    };
    const viewDatasets = () => {
      currentViewTitle.value = "Datasets and Filters";
      showKeywords.value = true;
      viewerStore.currentSidebarPanel = "datasets";
    };
    const viewLayers = () => {
      currentViewTitle.value = "Layers";
      showKeywords.value = false;
      viewerStore.currentSidebarPanel = "layers";
    };
    const viewHelp = () => {
      currentViewTitle.value = "Help";
      showKeywords.value = false;
      viewerStore.currentSidebarPanel = "help";
    };

    const toggleSidebar = () => {
      if (viewerStore.showSidebar) viewerStore.sidebarHide();
      else viewerStore.sidebarShow();
    };

    const router = useRouter();
    const route = useRoute();
    const reset = () => {
      // console.log("reset button");
      // reset and show a notification
      const infoNotifCb = (options: NotifyOptions) => {
        options.title = "Datasets, Filters, and Search reset";
      };
      notifyInfo(infoNotifCb);
      viewerStore.reset(router, route.query);
    };

    const { resultsCount } = inject(injectionSymbols.ViewerListPropsKey) as ViewerListProps;

    viewList();

    return {
      resultsCount,
      ChevronLeftIcon,
      MagnifyingGlassIcon,
      QuestionMarkCircleIcon,
      Square3Stack3DIcon,
      FunnelIcon,
      ArrowUturnLeftIcon,
      MapPinIcon,
      reset,
      toggleSidebar,
      currentViewTitle,
      viewDatasets,
      viewLayers,
      viewList,
      viewHelp,
      appSettingsStore,
      viewerStore,
      showKeywords,
    };
  },
});
</script>

<style module></style>
