import { fillMurnValues } from "@/components/lookup";

export default {
  responseAdapter,
};

async function responseAdapter(response: { data: Document }) {
  await fillMurnValues(response.data, ["typeMurn"]);
  return response.data;
}
