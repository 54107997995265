<template>
  <div class="mx-3 mt-2 block flex-1 p-1 sm:mx-5 md:mx-7 md:mt-0 lg:hidden">
    <!-- MOBILE menu -->
    <label for="current-tab" class="sr-only">Select a tab</label>
    <select
      id="current-tab"
      label="current-tab"
      class="w-full rounded-md border-r-8 border-transparent py-2 pl-3 pr-10 text-base ring-1 ring-neutral-300 focus-within:ring-neutral-400 focus-within:ring-2 focus-visible:outline-neutral-400"
      @change="navigateTo"
    >
      <option v-for="item in navigationItems" :key="item.name" :selected="item.name === $route.name" :value="item.name">
        {{ item.label }}{{ item.count || unref(item.count) == 0 ? " - " + unref(item.count) : "" }}
      </option>
    </select>
  </div>

  <!-- Desktop nav -->
  <router-link
    v-for="item in navigationItems"
    :key="item.name"
    :to="{ name: item.name, params: { ...$route.params } }"
    v-slot="{ href, navigate, isActive }"
    custom
  >
    <SkeletonBlock v-if="item.isPending" class="ml-2 mr-6 mb-2 h-8" />
    <a
      :href="href"
      @click="navigate"
      :aria-current="isActive ? 'page' : undefined"
      class="group mb-2 hidden w-full pl-2 pr-7 lg:flex"
      v-if="!item.isHidden"
    >
      <div
        class="flex flex-1 items-center rounded-l-md py-1.5 pl-4 pr-1 text-sm"
        :class="[
          isActive ? 'bg-neutral-500 text-white ' + $style.active : 'group-hover:bg-neutral-100',
          $style.sidebarNav,
        ]"
      >
        {{ item.label }}
        <span
          v-if="item.count || unref(item.count) == 0"
          :class="[
            isActive ? 'bg-neutral-700 text-neutral-100' : 'bg-neutral-100 text-neutral-600 group-hover:bg-neutral-300',
            'ml-auto inline-block rounded-full py-0.5 px-3 text-xs',
          ]"
        >
          {{ item.count }}
        </span>
      </div>
    </a>
  </router-link>
</template>

<script lang="ts">
import SkeletonBlock from "@/components/common/SkeletonBlock.vue";
import type { EntityNavigationItem } from "@/components/common/common.js";
import { PropType, defineComponent, unref } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
/** Component to display and control the entity navigation items.
 * It will show the list of navigation items and navigate the the desired screen on click.
 * It will also hide navigation items that are not authorized
 */
export default defineComponent({
  name: "TheEntityNavItems",
  props: {
    /** Array of the the navigation items (typically the entities children, notes, and documents) */
    navigationItems: {
      type: Array as PropType<EntityNavigationItem[]>,
      required: true,
      // TODO: Replace the validator with a Type?
      validator: (propValue: EntityNavigationItem[]) => {
        if (!propValue) return false;
        return propValue.every((x) => x.label && x.name);
      },
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const navigateTo = function ($event: any) {
      //technically $event is an Event, but TS is dumb
      router.push({ name: $event?.target?.value, params: { ...route.params } });
    };
    return {
      navigateTo,
      unref,
    };
  },
  components: { SkeletonBlock, RouterLink },
});
</script>

<style module>
.sidebarNav {
  position: relative;
}

.sidebarNav::after,
.sidebarNav::before {
  width: theme(spacing.4);
  height: 50%;
  position: absolute;
  left: 100%;
  content: "";
}

.sidebarNav::after {
  top: 50%;
  background: linear-gradient(to right bottom, theme(colors.white) 48%, transparent 51%);
}

.sidebarNav::before {
  top: 0px;
  background: linear-gradient(to right top, theme(colors.white) 48%, transparent 51%);
}
.sidebarNav:hover:after {
  background: linear-gradient(to right bottom, theme(colors.neutral.100) 48%, transparent 51%);
}

.sidebarNav:hover::before {
  background: linear-gradient(to right top, theme(colors.neutral.100) 48%, transparent 51%);
}
.sidebarNav.active::after {
  background: linear-gradient(to right bottom, theme(colors.neutral.500) 50%, transparent 53%);
}

.sidebarNav.active::before {
  background: linear-gradient(to right top, theme(colors.neutral.500) 50%, transparent 53%);
}
</style>
