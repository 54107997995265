<template>
  <FormKit
    type="form"
    id="theFormId"
    :value="currentDocument"
    v-model="currentDocument"
    @submit="handleSubmit"
    :actions="false"
    autocomplete="off"
  >
    <template #default="{ state: formState }">
      <div class="pb-5 sm:flex-1 sm:overflow-auto" v-if="currentDocument">
        <FormSection>
          <template #sectionName>
            {{ title }}
          </template>
          <template #default>
            <EntityDocumentLookupFields
              :category-murn="categoryMurn"
              group-name="docInfo"
              :filters="onFormFilter"
              :off-form-filters="filters"
            ></EntityDocumentLookupFields>
          </template>
        </FormSection>
      </div>
      <FormActions
        :save-action="saveAction"
        :cancel-action="cancelAction"
        :form-state="formState"
        :manual-disable="isInvalid"
      ></FormActions>
    </template>
  </FormKit>
</template>

<script lang="ts">
import type { Document, DocumentLoadingState, FileUpload, FileUploadResponse } from "@/components/entity/document";
import { getDocumentByIdOrNew, getDocuments, saveDocument } from "@/components/entity/document/entityDocumentApi";
import { useEntityDocumentConfigStore } from "@/components/entity/document/entityDocumentConfigStore";
import EntityDocumentLookupFields from "@/components/entity/document/EntityDocumentLookupFields.vue";
import { saveFileUpload } from "@/components/entity/document/fileUploadApi";
import { setIsInvalid } from "@/components/entity/document/helper";
import injectionSymbols from "@/components/entity/document/injectionSymbols";
import { default as FormActions } from "@/components/form/FormActions.vue";
import { default as FormSection } from "@/components/form/FormSection.vue";
import { LookupFiltersProp } from "@/components/lookup";
import LookupDropdown from "@/components/lookup/LookupDropdown.vue";
import { Paging, useApi } from "@/utils/api";
import { childRouteCancelAction } from "@/utils/helpers";
import { setErrors, submitForm } from "@formkit/core";
import { PropType, computed, defineComponent, provide, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

/** This is the create and edit screen for a *single* document. It allows the upload of a document and its details. */
export default defineComponent({
  name: "EntityDocumentLookup",
  emits: [],
  props: {
    /** Murn of the document category */
    categoryMurn: {
      type: String,
      required: true,
    },
    /** Name of the field ID (entityId) that the document belongs to */
    idField: {
      type: String,
      required: true,
    },
    /** Name of the route for the document list page for this entity */
    listName: {
      type: String,
      required: true,
    },
    /** Object containing additionalIds and their values that the document should be tied to (E.g. {exampleEntityId: 3}) */
    additionalIds: {
      type: Object,
      default: () => {},
    },
    /** Filters enable you to "cascade" dropdowns or to perform additional filtering based on Lookup metadata. This is the primary filters prop and will be used most of the time. */
    filters: {
      type: Object as PropType<LookupFiltersProp>,
    },
    /** Allow the component to automatically filter document type by the document category supplied. Default to true. */
    filterByCategoryMurn: {
      type: Boolean,
      default: true,
    },
    /** Allow the component to automatically filter document types by the collection limits in their metadata. This only looks at the other docs on the form, and not the rest of the documents on the entity. To include those, please add them to the 'filters' prop. Default to true. */
    filterByCollectionLimit: {
      type: Boolean,
      default: true,
    },
    /** Component will automatically grab the list of document types from the entity to include them in the collection limit. To disable that, set this prop to true. */
    disableAutomaticDocumentTypeFetch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const entityDocumentConfigStore = useEntityDocumentConfigStore();
    const route = useRoute();
    const router = useRouter();
    const isNew = !route.params.documentId;
    const title = computed(() => (isNew ? "New " : "Edit ") + entityDocumentConfigStore.documentLabel);

    const occupiedDocumentTypes = ref<string[]>([]);
    /*
      Always fetch the entity data again in an Edit component.
      (All others should inject what's provided by the *Base.vue component)
      This reduces the probability of running into a handful of issues.
    */
    const { dataRef: currentDocument, exec: fetchDocument } = useApi(getDocumentByIdOrNew, {
      initialData: {} as Document,
      responseAdapter: (response) => {
        if (response.data.fileName) {
          response.data.file = [{ name: response.data.fileName }];
        }

        let result;
        if (!isNew) {
          result = { ...response.data, docInfo: { ...response.data } };
        } else {
          result = response.data;
        }
        return result;
      },
    });

    const { exec: fetchDocuments } = useApi<Paging<Document>>(getDocuments, {
      responseAdapter: (response) => {
        return response.data;
      },
    });

    watch(
      () => route.params.documentId,
      (newValue, oldValue) => {
        if (isNew) {
          fetchDocument({ [props.idField]: parseInt(route.params.id as string) });
        } else if (!newValue) {
          return; // Catch route change invalidating the params.
        } else {
          fetchDocument({ id: newValue, [props.idField]: route.params.id });
        }

        if (!props.disableAutomaticDocumentTypeFetch) {
          const result: string[] = [];
          //TODO: Better way to do this: Take 500 because we want all documents - may need to increase
          fetchDocuments({ [props.idField]: route.params.id, take: 500 }).then((docs) => {
            docs?._items.forEach((doc) => {
              result.push(doc.typeMurn);
            });
            occupiedDocumentTypes.value = result;
          });
        }
      },
      { immediate: true },
    );

    const getParams = () => {
      const { documentId, ...rest } = route.params;
      return rest;
    };

    const cancelAction = function () {
      childRouteCancelAction({ router, routeName: props.listName, params: getParams() });
    };

    const { exec: execSaveDocument } = useApi(saveDocument, {
      initialData: {} as Document,
      responseAdapter: (response) => {
        cancelAction();
        return response.data;
      },
    });

    const { exec: execSaveFileUpload } = useApi<FileUpload, FileUploadResponse>(saveFileUpload);

    const handleSubmit = async (inData: Document) => {
      //since the document inputs are coming in as part of a group (not needed in the single one, but needed in multi), we need to take those out of the group
      const data: Document = { ...inData, ...inData["docInfo"] };
      delete data["docInfo"];
      try {
        if (isNew || data.file[0]?.file) {
          const uploadedDocs = await execSaveFileUpload({ files: data.file });
          if (uploadedDocs && !uploadedDocs[0]?.hasError) {
            data.fileUploadId = uploadedDocs[0]?.fileUploadId;
            data.fileSize = uploadedDocs[0]?.size;
            data.fileName = uploadedDocs[0]?.name;
            data.contentType = uploadedDocs[0]?.type;
          } else {
            throw new Error("File was not uploaded.");
          }
          for (let key in props.additionalIds) {
            data[key] = props.additionalIds[key];
          }
          data.categoryMurn = props.categoryMurn;
        }

        await execSaveDocument(data);
      } catch (errors) {
        console.error(errors);
        setErrors(
          "theFormId",
          ["There was an error saving; please try again."], // (optional) An array of form level errors
          //inputErrors // (optional) input level errors
        );
        return; // We can't re-throw, FormKit needs us to resolve.
      }
    };
    const saveAction = function () {
      submitForm("theFormId");
    };

    //starting out with blank filter in case collectionLimit and category filters are not wanted
    const onFormFilter: LookupFiltersProp = ref({});

    //add collection limit filter (creates references to the typeMurn inputs on the form)
    if (props.filterByCollectionLimit) {
      onFormFilter.value["collectionLimit"] = computed(() => {
        const result = [];
        if (currentDocument.value)
          for (const [key, value] of Object.entries(currentDocument.value)) {
            if (key.startsWith("docInfo")) {
              result.push(currentDocument.value?.[key].typeMurn);
            }
          }
        if (!props.disableAutomaticDocumentTypeFetch) {
          return [...result, ...occupiedDocumentTypes.value];
        }
        return result;
      });
    }

    //add category filter
    if (props.filterByCategoryMurn) {
      onFormFilter.value["category"] = props.categoryMurn;
    }

    //get the state of the upload from the child component
    const isInvalid = ref(false);

    const documentSectionStates = new Map();

    const registerAndUpdateDocumentSectionStates = (sectionName: string, states: DocumentLoadingState) => {
      documentSectionStates.set(sectionName, states);
      isInvalid.value = setIsInvalid(documentSectionStates);
    };

    provide(injectionSymbols.UploadInProgressKey, { registerAndUpdateDocumentSectionStates });

    return {
      cancelAction,
      saveAction,
      handleSubmit,
      currentDocument,
      // isNew,
      // booleanOptions,
      entityDocumentConfigStore,
      onFormFilter,
      isInvalid,
      title,
    };
  },
  components: {
    FormActions,
    FormSection,
    LookupDropdown,
    EntityDocumentLookupFields,
  },
});
</script>
