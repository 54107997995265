import { useEntityDocumentConfigStore } from "@/components/entity/document/entityDocumentConfigStore";
import { getLookupGroupByIdOrCode } from "@/components/lookup/lookupGroupApi";
import { useApi } from "@/utils/api";

/**
 * @param categoryMurn Murn to filter the document types by category with
 * @returns A list of all available extensions for *all* document types within a certain category
 */
export const getBulkExtensions = async (categoryMurn: string) => {
  const allExt: string[] = [];
  const { documentTypeLookupGroupCode } = useEntityDocumentConfigStore();
  const { exec: fetchLookup } = useApi(getLookupGroupByIdOrCode);
  const group = await fetchLookup(documentTypeLookupGroupCode);
  const lookups = group?.lookups._items;

  lookups?.forEach((lookup) => {
    if (lookup.metadata?.filtersOn?.category.values.includes(categoryMurn)) {
      if (lookup.metadata.extensions) {
        const extensions: string[] = lookup.metadata.extensions.split(",");
        allExt.push(...extensions);
      }
    }
  });
  const uniqueExt = Array.from(new Set(allExt)).toString();
  return uniqueExt;
};

/**
 * @param documentMap Map used to distinguish between document objects
 * @returns `true` or `false` based on a documents valid state. Returns `true` if any document has an invalid state.
 */
export const setIsInvalid = (documentMap: Map<string, any>) => {
  for (const [key, value] of documentMap) {
    if (value.typeExtensionsLoading || value.bulkExtensionsLoading || value.isInvalidState || value.uploadInProgess)
      return true;
  }
  return false;
};
