<template>
  <FormKit type="group" :name="groupName" v-model="currentDocument">
    <FormKit
      type="file"
      name="file"
      label="File"
      :accept="currentDocument.typeMurn ? currentDocument.typeMurn && extensions : bulkAccept"
      file-remove-icon="close"
      file-item-icon="fileDoc"
      validation="required"
      @input.passive="fileSelected($event as unknown as Event)"
      :errors="fileErrors"
      title=" "
      :disabled="typeExtensionsLoading || bulkExtensionsLoading || fileUploadInProgress"
      :help="fileUploadInProgress ? 'Uploading...' : ''"
    />
    <LookupDropdown
      label="Type"
      :group-code="documentTypeLookupGroupCode"
      name="typeMurn"
      :additionalFilters="offFormFilters"
      :filters="filters"
      :errors="typeErrors"
      validation="required"
      :selection-removable="false"
    ></LookupDropdown>
    <FormKit type="text" label="Display Name" name="displayName" validation="required:trim|length:0,100" />
    <FormKit type="text" label="Comment" name="comment" validation="length:0,255" id="comment" />
  </FormKit>
</template>

<script lang="ts">
import BaseButton from "@/components/button/BaseButton.vue";
import {
  useEntityDocumentConfigStore,
  type FileUpload,
  type FileUploadResponse,
  type UploadInProgress,
} from "@/components/entity/document";
import { saveFileUpload } from "@/components/entity/document/fileUploadApi";
import { getBulkExtensions } from "@/components/entity/document/helper";
import injectionSymbols from "@/components/entity/document/injectionSymbols";
import { default as FormActions } from "@/components/form/FormActions.vue";
import { default as FormSection } from "@/components/form/FormSection.vue";
import { LookupFiltersProp, getLookupMetadata } from "@/components/lookup";
import LookupDropdown from "@/components/lookup/LookupDropdown.vue";
import { useApi } from "@/utils/api";
import { booleanOptions } from "@/utils/helpers";
import { PropType, computed, defineComponent, inject, onMounted, ref, unref, watch } from "vue";
import { useRoute } from "vue-router";

/** This component is for the form fields required by a document and is used in the create and edit screen for a document. */
export default defineComponent({
  name: "EntityDocumentLookupFields",
  emits: [],
  props: {
    /** Murn of the document category */
    categoryMurn: {
      type: String,
      required: true,
    },
    /** Name of the formkit form group. Need to distinguish between documents (in the instance this is used in multi document upload) */
    groupName: {
      type: String,
      required: true,
    },
    /** Object containing additionalIds and their values that the document should be tied to (E.g. {exampleEntityId: 3}) */
    additionalIds: {
      type: Object,
      default: () => {},
    },
    //TODO: filters rename
    /** Filters enable you to "cascade" dropdowns or to perform additional filtering based on Lookup metadata. This is the primary filters prop and will be used most of the time. */
    filters: {
      type: Object as PropType<LookupFiltersProp>,
    },
    /** Filters enable you to "cascade" dropdowns or to perform additional filtering based on Lookup metadata. This one is specifically used for collectionLimits. It is used *in addition* to the filters prop. The filters prop should stil be the main prop used. This one is for static fields (fields that need to be included in the filter but are *not* part of the form.) */
    offFormFilters: {
      type: Object as PropType<LookupFiltersProp>,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const isNew = !route.params.documentId;
    const { documentTypeLookupGroupCode } = useEntityDocumentConfigStore();

    const currentDocument = ref();

    const typeErrors = ref<Array<string>>([]);
    const fileErrors = ref<Array<string>>([]);
    const fileUploadInProgress = ref(false);

    const isInvalidState = computed(() => {
      return typeErrors.value.length > 0 || fileErrors.value.length > 0;
    });

    const { registerAndUpdateDocumentSectionStates } = inject(injectionSymbols.UploadInProgressKey) as UploadInProgress;

    const updateState = () => {
      //wrapper function for less typing on repeated calls
      registerAndUpdateDocumentSectionStates(props.groupName, {
        typeExtensionsLoading: typeExtensionsLoading.value,
        bulkExtensionsLoading: bulkExtensionsLoading.value,
        isInvalidState: isInvalidState.value,
        uploadInProgess: fileUploadInProgress.value,
      });
    };

    const typeExtensionsLoading = ref(false);
    const bulkExtensionsLoading = ref(false);
    const extensions = ref();

    updateState();

    function getDocTypeMetaPromise(murn: string) {
      typeExtensionsLoading.value = true;
      return getLookupMetadata(murn).then((meta) => {
        if (!meta?.extensions) throw "There are no extensions for this document type";
        extensions.value = meta.extensions;
        typeExtensionsLoading.value = false;
      });
    }

    const bulkAccept = ref(); //value of all extensions allowed for all document types under the given category murn

    bulkExtensionsLoading.value = true;
    getBulkExtensions(props.categoryMurn).then((result) => {
      // setTimeout(() => {
      bulkAccept.value = result;
      bulkExtensionsLoading.value = false;
      // }, 4000);
    });

    watch(
      [() => currentDocument.value?.typeMurn, () => currentDocument.value?.file?.[0]],
      ([newType, newFile]) => {
        if (newType && !newFile) {
          getDocTypeMetaPromise(newType);
        }
        //here
        if (newType && newFile) {
          getDocTypeMetaPromise(newType).then(() => {
            const exts: string[] = extensions?.value.split(",");
            const fileName = newFile?.name;

            if (fileName && exts && !exts.some((x) => fileName.endsWith(x))) {
              const extsList = exts.join(", ");
              fileErrors.value = [`Format invalid for selected type. Please upload a ${extsList} file.`];
              typeErrors.value = ["Type is invalid for the selected file."];
            } else {
              fileErrors.value = [];
              typeErrors.value = [];
            }
            updateState();
          });
        } else {
          fileErrors.value = [];
          typeErrors.value = [];
          bulkExtensionsLoading.value = true;

          getBulkExtensions(props.categoryMurn).then((result) => {
            bulkAccept.value = result;
            bulkExtensionsLoading.value = false;
            updateState();
          });
        }
      },
      { immediate: true },
    );
    watch(
      () => currentDocument.value?.file?.[0],
      (newFile) => {
        //this one is in a seperate watch than the other one, even though its watching the same thing, because it messes up the upload trigger
        if (newFile) {
          uploadAndUpdate();
        }
      },
      { immediate: true },
    );

    const { dataRef: fileUploadResult, exec: execSaveFileUpload } = useApi<FileUpload, FileUploadResponse>(
      saveFileUpload,
    );

    const uploadAndUpdate = async () => {
      if (isNew || currentDocument.value.file[0]?.file) {
        fileUploadInProgress.value = true;
        updateState();

        await execSaveFileUpload({ files: currentDocument.value.file }).then(async (uploadedDocs) => {
          // setTimeout(() => {
          if (uploadedDocs && !uploadedDocs[0]?.hasError) {
            currentDocument.value.fileUploadId = uploadedDocs[0]?.fileUploadId;
            currentDocument.value.fileSize = uploadedDocs[0]?.size;
            currentDocument.value.fileName = uploadedDocs[0]?.name;
            currentDocument.value.contentType = uploadedDocs[0]?.type;
          } else {
            fileErrors.value = [`File could not be uploaded`];
            currentDocument.value.file = undefined;
            // throw new Error("File was not uploaded.");
          }
          for (let key in props.additionalIds) {
            currentDocument.value[key] = props.additionalIds[key];
          }
          currentDocument.value.categoryMurn = props.categoryMurn;
          fileUploadInProgress.value = false;
          updateState();
          // }, 4000);
        });
      }
    };

    const fileSelected = ($event: Event) => {
      const input = $event.target as HTMLInputElement;
      if (input.files?.[0]?.name && !currentDocument.value?.displayName) {
        if (!currentDocument.value) return;

        let name = input.files[0].name;
        const dot = input.files[0].name.lastIndexOf(".");
        if (dot > -1) {
          name = name.substring(0, dot);
        }
        currentDocument.value.displayName = name;
      }
    };

    return {
      documentTypeLookupGroupCode,
      fileSelected,
      booleanOptions,
      typeErrors,
      fileErrors,
      extensions,
      typeExtensionsLoading,
      bulkExtensionsLoading,
      currentDocument,
      fileUploadInProgress,
      bulkAccept,
    };
  },
  components: {
    FormActions,
    FormSection,
    LookupDropdown,
    BaseButton,
  },
});
</script>
