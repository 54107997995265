import { useApi } from "@/utils/api/useApi.js";
import { ensureArray } from "@/utils/helpers/ensureArray.js";
import type { FormKitNode } from "@formkit/core";
import { computed, ref, toValue } from "vue";
import type { Lookup, LookupFiltersProp } from "./lookup.js";
import { getLookupGroupByIdOrCode } from "./lookupGroupApi.js";

/**
 * Primarily used by LookupDropdown, it allows a caller to get a list of Lookups filtered based on whether they're active or the initial value.
 * @param groupCode The groupCode used by the methods and objects returned by this composable.
 * @param node Optional FormKitNode - useful when you need to reset the value of a component that uses the options returned by this method.
 * @returns
 */
export function useLookup(groupCode: string, node?: FormKitNode<any>) {
  const initialValue = ref<string | undefined>();
  const isLoading = ref(true);
  const options = ref<{ label: string; value: string }[]>([]);

  const { exec: fetchLookup } = useApi(getLookupGroupByIdOrCode, {
    responseAdapter: (response) => {
      return response.data;
    },
  });

  const filterAndConvertLookups = (
    lookups: Lookup[],
    filters: LookupFiltersProp | undefined,
    currentValue: string | string[] | undefined,
    additionalFilters?: LookupFiltersProp | undefined,
  ) => {
    let isModelValueInLookups = false;
    const isCurrentValueArray = Array.isArray(currentValue);
    const isCurrentValueArrayInLookups = isCurrentValueArray ? currentValue.map((x) => false) : [];
    const lookupResult = new Array<{ label: string; value: string; sortWeight: number }>();

    lookups.forEach((lookup) => {
      let isIncluded;

      if (!lookup.isActive && lookup.murn !== initialValue.value) {
        // Only include Active + InitialValue (i.e. current)

        isIncluded = false;
      } else if (!lookup.metadata?.filtersOn || (!filters && !additionalFilters)) {
        // If there are no other filters, include it.

        isIncluded = true;
      } else {
        // Otherwise include based on the props.filters

        const additionalCountMap = new Map(); //static filter values - like from sister entity - used for collectionLimit
        const onFormCountMap = new Map(); //values for the field on the form - needs to be seperate than the off form because this can change - those cannot - used for collectionLimit
        isIncluded = Object.entries(lookup.metadata.filtersOn).every(([key, metadata]) => {
          if (key == "collectionLimit") {
            const additionalFilterValue = toValue(toValue(additionalFilters)?.[key]);
            const onFormFilterValue = toValue(toValue(filters)?.[key]);

            if (
              (!additionalFilterValue || additionalFilterValue.length === 0) &&
              (!onFormFilterValue || onFormFilterValue.length === 0)
            )
              return true;

            const additionalFilterValues = ensureArray(additionalFilterValue);
            const onFormFilterValues = ensureArray(onFormFilterValue);

            //get count for the filters that are not part of the form
            additionalFilterValues.forEach((value) => {
              let filter = additionalCountMap.get(value);
              if (!filter) {
                filter = { count: 0 };
                additionalCountMap.set(value, filter);
              }
              filter.count += 1;
            });

            //get count for the filters that are part of the form
            onFormFilterValues.forEach((value) => {
              let filter = onFormCountMap.get(value);
              if (!filter) {
                filter = { count: 0 };
                onFormCountMap.set(value, filter);
              }
              filter.count += 1;
            });

            //If it has filters in either we need to handle it. They are both handled differently than each other, but they are both handled differently than none at all
            if (additionalCountMap.get(lookup.murn) || onFormCountMap.get(lookup.murn)) {
              let additionalCount = 0; //we need this so we know if we are reaching out limit with values that we cannot change
              let onFormCount = 0; //we need this so we know if we are reaching/under out limit with values that *CAN* change
              if (additionalCountMap.get(lookup.murn)) {
                additionalCount = additionalCountMap.get(lookup.murn).count;
              }

              if (onFormCountMap.get(lookup.murn)) {
                onFormCount = onFormCountMap.get(lookup.murn).count;
              }

              let isIncludedOffForm = typeof metadata.val === "number" ? metadata.val > additionalCount : false; //  can this one be included based soley on the static filter values and the limit?
              //We need to know if it is the current value otherwise the limit will kick out the value the second it is reached on the form, even though it can be there
              let isCurrentValue;
              if (isCurrentValueArray) {
                isCurrentValue = currentValue.includes(lookup.murn);
              } else {
                isCurrentValue = lookup.murn === currentValue;
              }

              let isIncludedOnForm =
                (typeof metadata.val === "number" ? metadata.val > onFormCount + additionalCount : false) ||
                isCurrentValue; //determining if the limit has been reached based on the static values AND what is currently on the form. Also, need to know if it is the current value so it doesnt clear the input. Because this could be the input that is reaching the limit and that is fine

              return isIncludedOffForm && isIncludedOnForm; //only include it if both checks pass
            } else {
              return typeof metadata.val === "number" ? metadata.val > 0 : false; //if there are no filter values present, then we only need to know the limit allows for one
            }
          } else {
            const filterValue = toValue(toValue(filters)?.[key]);
            if (!filterValue || filterValue.length === 0) return true;

            const filterValues = ensureArray(filterValue);
            return filterValues.some((value) => metadata.values.includes(value));
          }
        });
      }

      if (isIncluded) {
        lookupResult.push({
          label: lookup.description,
          value: lookup.murn,
          sortWeight: lookup.sortWeight,
        });

        if (isCurrentValueArray) {
          const index = currentValue.indexOf(lookup.murn);
          if (index > -1) isCurrentValueArrayInLookups[index] = true;
        } else if (lookup.murn === currentValue) {
          isModelValueInLookups = true;
        }
      }
    });

    // If the current value is no longer in the lookup, it needs to be reset
    if (isCurrentValueArray) {
      if (currentValue?.length > 0 && isCurrentValueArrayInLookups.some((x) => !x) && node) {
        node.input([]);
      }
    } else if (currentValue && !isModelValueInLookups && node) {
      node.input(undefined);
    }

    lookupResult.sort((a, b) => a.sortWeight - b.sortWeight);

    return lookupResult;
  };

  const loadOptions = async (
    filters: LookupFiltersProp | undefined,
    currentValue: string | string[] | undefined,
    additionalFilters?: LookupFiltersProp | undefined,
  ) => {
    options.value = [];
    isLoading.value = true;
    try {
      const group = await fetchLookup(groupCode);
      if (!group) {
        options.value = [];
        return options.value;
      }

      const lookups = group.lookups._items;
      options.value = filterAndConvertLookups(lookups, filters, currentValue, additionalFilters);
    } catch (error) {
      console.error("Failed to load options:", error);
      options.value = [];
    } finally {
      isLoading.value = false;
    }
    return options.value;
  };

  return {
    /** The initialValue may not be available when calling useLookup. This allows the caller to specify it later. */
    initialValue,
    /** Boolean indicating whether the `options` are being fetched or filtered. */
    isLoading,
    /** A FormKit friendly sorted array of Lookups. Filtered where `isActive` is `true` or the Lookup's Murn equals the initialValue. */
    options,
    /**
     * Hydrates the `options` list.
     * @param filters Additional filters to apply beyond isActive and whether it matches the `initialValue`
     * @param currentValue If `node` was specified when calling `useLookup`, this will check to see if  `currentValue` is included in the filtered list. If not, the node's value will be set to `[]` for arrays and `undefined` for all other cases.
     * @returns
     */
    loadOptions,
    /**
     * Filters, converts, and sorts a Lookup array.
     * Includes Lookups matching the `initialValue` specified in `useLookup`, or where `isActive` = `true`.
     * Sorts using the `Lookup.sortWeight` property.
     * @param lookups The array of Lookup objects to filter
     * @param filters Additional filters to apply beyond isActive and whether it matches the `initialValue`
     * @param currentValue If `node` was specified when calling `useLookup`, this will check to see if  `currentValue` is included in the filtered list. If not, the node's value will be set to `[]` for arrays and `undefined` for all other cases.
     * @returns
     */
    filterAndConvertLookups,
  };
}
