import { useAppSettingsStore } from "@/components/common/appSettingsStore.js";
import type { notifyErrorCb, notifySuccessCb } from "@/components/notifications/notifications.js";
import { NotifyOptionsError } from "@/components/notifications/NotifyOptionsError.js";
import { NotifyOptionsSuccess } from "@/components/notifications/NotifyOptionsSuccess.js";
import { useNotifier } from "@/components/notifications/useNotifier.js";
import type { Paging, TypeOfPromise } from "@/utils/api/api.js";
import { AxiosError, type AxiosInstance, type AxiosResponse, type CreateAxiosDefaults, default as axios } from "axios";
import {
  axiosResponseCachingErrorInterceptor,
  getAxiosRequestCachingInterceptor,
  getAxiosResponseCachingInterceptor,
} from "./apiCaching";
import { APP_VERSION, PRODUCT_VERSION } from "./constants/headers";

const { notifySuccess, notifyError } = useNotifier();

type cachedApisMap = {
  [key: symbol]: ReturnType<typeof api>;
};

const cachedApis: cachedApisMap = {};

type apisMap = {
  [key: symbol]: ReturnType<typeof api>;
};

const apis: apisMap = {};

// Default config for the axios instance
//TODO: merge default params with whatever is passed in?
// const axiosParams = {
// };

/**
 * Also passes through axios config options
 */
export class ApiOptions {
  /**
   *
   * @param {NotifyOptionsSuccess} successNotifCb
   * @param {NotifyOptionsError} errorNotifCb
   */
  constructor(successNotifCb: NotifyOptionsSuccess, errorNotifCb: NotifyOptionsError) {
    this.successNotifCb = successNotifCb;
    this.errorNotifCb = errorNotifCb;
  }
  /**
   * Notification configuration for success messages
   * @type {NotifyOptionsSuccess}  */
  successNotifCb: NotifyOptionsSuccess;
  /**
   * Notification configuration for error messages
   * @type {NotifyOptionsError}  */
  errorNotifCb: NotifyOptionsError;
}

const isCancel = (error: any) => axios.isCancel(error);
export const didAbort = (error: any) => error?.aborted;

const withAbort =
  <T>(
    axiosCall: typeof axios.get | typeof axios.put | typeof axios.post | typeof axios.patch | typeof axios.delete,
    hasOneArg = false,
  ) =>
  async (...args: any[]) => {
    const originalConfig = args[args.length - 1];
    // Extract abort property from the config
    let { abort, ...config } = originalConfig;

    // Add AbortController signal and abort method only if abort function was passed in
    if (typeof abort === "function") {
      const controller = new AbortController();
      config.signal = controller.signal;
      // The Abort method *must* be bound to its controller or the fn invocation will fail when we call it outside of this context.
      abort(controller.abort.bind(controller));
    }

    try {
      // Pass all arguments from args besides the original config
      // Axios wraps their methods, so we can't rely on any properties of the method to determine arg requirements.
      if (hasOneArg) {
        return await axiosCall<T>(args[0], config);
      } else {
        return await axiosCall<T>(args[0], args[1], config);
      }
    } catch (error: any) {
      // Add "aborted" property to the error if the request was canceled
      let newError = { ...error };
      isCancel(error) && (newError.aborted = true);
      throw newError;
    }
  };

// Main api function
const api = <T, PagingType>(axiosInstance: AxiosInstance) => {
  const withLogger = async <
    LogType,
    P extends Promise<AxiosResponse<LogType, any>> = Promise<AxiosResponse<LogType, any>>,
  >(
    promise: P,
  ) =>
    promise.catch((error: any | AxiosError) => {
      if (didAbort(error)) throw error; // as AxiosError<LogType, any>; //Don't log aborted requests.

      // Always log errors in dev environment
      if (process.env.NODE_ENV !== "development") throw error;
      // Log error only if VUE_APP_DEBUG_API env is set to true
      //if (!process.env.VUE_APP_DEBUG_API) throw error;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("ErrorData", error.response.data);
        console.log("ErrorStatus", error.response.status);
        console.log("ErrorHeaders", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest
        // in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("ErrorRequest", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("ErrorConfig", error.config);
      throw error;
    });

  /**
   * Wraps a promise with handlers for showing success / error notifs.
   *
   * successNotifCb Configuration - title is required
   */

  const withNotifier = <T extends Promise<TypeOfPromise<T>>>(
    promise: T,
    successNotifCb: notifySuccessCb,
    errorNotifCb: notifyErrorCb,
  ) => {
    if (successNotifCb) {
      promise
        .then((result) => {
          notifySuccess(successNotifCb);
        })
        .catch(() => {
          //Do nothing, errors will be getting handled elsewhere.
        });
    }
    if (errorNotifCb) {
      promise.catch((error) => {
        if (!didAbort(error)) {
          notifyError(errorNotifCb, error?.response?.status);
        }
        // This isn't swallowing the error because we aren't returning the .catch
      });
    }
    return promise;
  };

  return {
    /**
     * @param {string} url
     * @param {any} config
     * @returns {withNotifier}
     */
    getPaged: <PT = PagingType>(url: string, config: any = {}) =>
      withNotifier(
        withLogger<PT, Promise<AxiosResponse<PT, any>>>(withAbort<PT>(axiosInstance.get, true)(url, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    get: (url: string, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axiosInstance.get, true)(url, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    post: (url: string, body: any, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axiosInstance.post)(url, body, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    put: (url: string, body: any, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axiosInstance.put)(url, body, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    patch: (url: string, body: any, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axiosInstance.patch)(url, body, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
    delete: (url: string, config: any = {}) =>
      withNotifier(
        withLogger<T>(withAbort<T>(axiosInstance.delete, true)(url, config)),
        config.successNotifCb,
        config.errorNotifCb,
      ),
  };
};

/** Recusively renames a property (defaults to "value") provided by the server to "_items" on all children */
export function adaptValueToItems(response: any, fieldName: string = "value") {
  if (response !== null && typeof response == "object") {
    Object.entries(response).forEach(([key, value]: any) => {
      if (key === fieldName && Array.isArray(value)) {
        response._items = value;
        delete response[fieldName];
      }
      adaptValueToItems(value, fieldName);
    });
  }
  return response;
}

/**
 * cachedApiService will provide a cachedApi experience if it's available. If not, it will fallback to a plain apiService.
 * This uses Web API CacheStorage - https://developer.mozilla.org/en-US/docs/Web/API/CacheStorage
 * Currently only `get` requests are cached.
 *
 * @param {symbol} apiHost A unique key specifying an apiHost, usually found in constants/apiHosts.js. For the cachedApiService this should be a string based Symbol!
 * @param {CreateAxiosDefaults} axiosParams Axios parameters object (e.g. baseURL). See axios docs.
 * @returns
 */
export const cachedApiService = <T, PagingType = Paging<T>>(apiHost: symbol, axiosParams: CreateAxiosDefaults) => {
  if (!cachedApis[apiHost]) {
    const instance = axios.create(axiosParams);
    instance.interceptors.response.use(adaptValueToItems);

    // Interceptor to throw an error containing cached data and cancel the request if cached data is available.
    // The axiosResponseCachingErrorInterceptor method below will catch the error and return the cached data.
    instance.interceptors.request.use(getAxiosRequestCachingInterceptor(apiHost.description));

    // Response interceptor to cache successful responses
    instance.interceptors.response.use(
      getAxiosResponseCachingInterceptor(apiHost.description),
      axiosResponseCachingErrorInterceptor,
      {},
    );

    cachedApis[apiHost] = api<T, PagingType>(instance);
  }
  return cachedApis[apiHost] as ReturnType<typeof api<T, PagingType>>;
};

/**
 * @param {symbol} apiHost A unique key specifying an apiHost, usually found in constants/apiHosts.js
 * @param {CreateAxiosDefaults} axiosParams Axios parameters object (e.g. baseURL). See axios docs.
 */
export default function apiService<T, PagingType = Paging<T>>(apiHost: symbol, axiosParams: CreateAxiosDefaults) {
  if (!apis[apiHost]) {
    const instance = axios.create(axiosParams);
    instance.interceptors.response.use(adaptValueToItems);

    // Self-destructing interceptor is meant to only run once, but due to quirks of axios runs a few times before being destroyed.
    // Sets the appVersion and productVersion in the appSettingsStore
    let interceptorNumber: null | number = null;
    interceptorNumber = instance.interceptors.response.use(async (response) => {
      const appSettingsStore = useAppSettingsStore();

      // There are a lot of apiHosts, make sure we're only setting the application's version based on the source system's and not one of the others.
      if (apiHost !== appSettingsStore.apiHost) return response;

      // We only want to set this once.
      if (interceptorNumber && (appSettingsStore.appVersion || appSettingsStore.productVersion)) {
        // Remove the interceptor
        instance.interceptors.response.eject(interceptorNumber);
      } else {
        if (appSettingsStore.appVersion === "") appSettingsStore.appVersion = response.headers[APP_VERSION] ?? "";

        if (appSettingsStore.productVersion === "")
          appSettingsStore.productVersion = response.headers[PRODUCT_VERSION] ?? "";
      }

      return response;
    });

    apis[apiHost] = api<T, PagingType>(instance);
  }
  return apis[apiHost] as ReturnType<typeof api<T, PagingType>>;
}
