import { NotifyOptions } from "@/components/notifications/NotifyOptions.js";
import { NotifyOptionsError } from "@/components/notifications/NotifyOptionsError.js";
import { NotifyOptionsSuccess } from "@/components/notifications/NotifyOptionsSuccess.js";
import type { useNotifierResult } from "@/components/notifications/notifications.js";
import { notify } from "notiwind";

// Wrapper / helper methods for notiwind
/** Wrapper /helper methods for notiwind */
export function useNotifier(): useNotifierResult {
  return {
    /**
     * Display a Success Notification
     *
     * @param {NotifyOptionsSuccess} optionsCb Configuration - title is required
     */
    notifySuccess: (optionsCb) => {
      const options = new NotifyOptionsSuccess(false, "generic", "success", "", "", "", "", "", 3100);

      optionsCb(options);
      if (options.hide) return;

      notify(options.toOptions(), options.time);
    },

    /**
     * Display an Information Notification
     *
     * @param {NotifyOptions} optionsCb Configuration - title is required
     */
    notifyInfo: (optionsCb: (options: NotifyOptions) => void): void => {
      const options = new NotifyOptions(false, "generic", "info", "", "", 3100);

      optionsCb(options);
      if (options.hide) return;

      notify(options.toOptions(), options.time);
    },
    /**
     * Display an Error Notification
     *
     * @param {NotifyOptionsError} optionsCb Configuration - text is required
     */
    notifyError: (optionsCb: (options: NotifyOptionsError) => void, statusCode: number): void => {
      const options = new NotifyOptionsError(false, "errors", "", "Error", "", "", "", "", "", 9000000000, false);

      optionsCb(options);
      // TODO: Remove reference to deprecated hide403 field.
      if (options.hide || (options.hide403 && statusCode === 403) || options.hideStatusCodes?.includes(statusCode))
        return;

      notify(options.toOptions(), options.time);
    },
  };
}
