import { AuthRequest } from "@/utils/auth/authorization/index.js";
import { useAuthorization } from "@/utils/auth/authorization/useAuthorization";
import { ComputedRef, Ref, computed, readonly, ref, shallowRef, type VueElement } from "vue";
import type { JSX } from "vue/jsx-runtime";

/** Object used to specify configuration for a single navigation item. Converted by `setNavigationItems` into a {@link AppNavItem}  */
export interface AppNavItemConfig {
  /** Label shown on the UI */
  label: string;
  /** Route name for navigation */
  name: string;
  /** May be displayed on the meganav, or more commonly, used to describe reports in Analytics */
  description?: string;
}

/** A single navigation item */
export type AppNavItem = Required<AppNavItemConfig> & {
  /** Used internally to identify an AppNavItem for authorization */
  id: number;
  /** Flag indicating whether the user is authorized to see this nav item, it will be set by `setNavigationItems` */
  isAuthorized: Ref<boolean>;
  /** Flag indicating whether a request for authorization is pending, it will be set by `setNavigationItems` */
  isAuthPending: Ref<boolean>;

  // isFavorite: Ref<boolean>; // TODO: add in the future
};

/** A group of {@link AppNavItemConfig} */
export interface AppNavItemGroupConfig {
  /** Group Label shown on the UI, it can be hidden using the `showLabel` property */
  label: string;
  /** String containing CSS classes to be included on the group - used for responsively moving groups. */
  className: string;
  /** Determines whether the group's label is shown. @default true */
  showLabel?: boolean;
  /** An array of {@link AppNavItemConfig} rendered by this group. */
  items: AppNavItemConfig[];
}

/** A group of {@link AppNavItem} */
export type AppNavItemAuthGroup = Pick<Required<AppNavItemGroupConfig>, "label" | "className" | "showLabel"> & {
  /** An array of {@link AppNavItem} rendered by this group. */
  items: AppNavItem[];
  /** Indicates whether one or more items in the group are authorized */
  isAuthorized: ComputedRef<boolean>;
  /** Flag indicating whether a request for authorization is pending, it will be set by `setNavigationItems` */
  isAuthPending: Ref<boolean>;
};

let idCount = 0;

const NAV_DEFAULT = Symbol("default");
const NAV_USER = Symbol("user");
const navigationItems = ref<{ [key: symbol]: AppNavItemAuthGroup[] }>({ [NAV_DEFAULT]: [], [NAV_USER]: [] });
const areFavoritesEnabled = ref(false); //hardcoded for now until faves are enabled.
const homeRouteName = ref("Home"); //This may be overridden by the source system.

const applicationTitleComponent = ref<JSX.Element | ((...args: any) => JSX.Element)>();

/** Sets the route name used when clicking on the system name in the header
 *
 * @param name The route name
 */
export const setHomeRouteName = (name: string) => {
  homeRouteName.value = name;
};

/**
 *
 * @param component A JSX.Element or a method returning one that will be rendered as the Application Title.
 */
export const setApplicationTitleComponent = (component: any) => {
  applicationTitleComponent.value = component;
};

/** Accepts an array of AppNavItemGroup<AppNavItemConfig> items and adds the Authorization for them and then sets them to the {@link navigationItems}
 *
 * @param {AppNavItemAuthGroup<AppNavItemConfig>[]} groups The groups of desired navigation items that need authorization fields added.
 */
export const setNavigationItems = (groups: AppNavItemGroupConfig[], key = NAV_DEFAULT) => {
  navigationItems.value[key] = processNavigationItems(groups);
};

function processNavigationItems(groups: AppNavItemGroupConfig[]): AppNavItemAuthGroup[] {
  const { authorizationClient, AuthRequest, AUTH_REQUEST_PREEMPTED } = useAuthorization();

  const authorizationRequests: AuthRequest[] = [];

  groups.forEach((group) => {
    group.items.forEach((item) => {
      // Update the AppNavItemConfig to be an AppNavItem.
      const navItem = item as AppNavItem;
      navItem.id = idCount++;
      navItem.isAuthorized = ref(false);
      navItem.isAuthPending = ref(true);

      const result = new AuthRequest();
      result.permissionName = "ReadNavItem";
      result.resource = { name: navItem.name };
      result.correlationId = "ReadNavItem-" + navItem.id;
      authorizationRequests.push(result);
    });
    group.showLabel = group.showLabel !== false;
    // Update the AppNavItemGroupConfig to be an AppNavItemGroup
    const navGroup = group as AppNavItemAuthGroup;
    navGroup.isAuthorized = computed(() => navGroup.items.some((x) => x.isAuthorized?.value));
    navGroup.isAuthPending = ref(true);
  });

  const navGroups = groups as AppNavItemAuthGroup[];
  authorizationClient.authorizeNavigationRequests(authorizationRequests).then((result) => {
    result.forEach((response: any) => {
      navGroups.forEach((group) => {
        group.isAuthPending.value = false;
        for (let i = 0; i < group.items.length; ++i) {
          if (response.correlationId === "ReadNavItem-" + (group.items[i] as AppNavItem).id) {
            group.items[i].isAuthorized.value = response.isSuccess;
            group.items[i].isAuthPending.value = false;
            break;
          }
        }
      });
    });
  });

  // We've added the missing properties above, so this is okay.
  return navGroups;
}

/** Returns a readonly copy of the AppNavItemGroup[] for a given key */
export const getNavigationItems = (key: symbol) => {
  return readonly(navigationItems.value[key]);
};

/** Accepts an array of AppNavItemGroup<AppNavItemConfig> items and adds the Authorization for them and then sets them to the {@link userNavigationItems}
 *
 * @param {AppNavItemGroupConfig[]} items The groups of desired navigation items that need authorization fields added.
 */
export const setUserNavigationItems = (items: AppNavItemGroupConfig[]) => {
  setNavigationItems(items, NAV_USER);
};

/** Adds one or more AppNavItemGroupConfig objects to the {@link userNavigationItems}
 *
 * @param {AppNavItemGroupConfig[] | AppNavItemGroupConfig} newItems An array of userNavigationItems or a single userNavigationItems object.
 */
export const addUsesrNavigationItems = (newItems: AppNavItemGroupConfig[] | AppNavItemGroupConfig) => {
  let newGroups: AppNavItemAuthGroup[];
  if ("forEach" in newItems) {
    newGroups = processNavigationItems(newItems);
  } else {
    newGroups = processNavigationItems([newItems]);
  }
  navigationItems.value[NAV_USER] = navigationItems.value[NAV_USER].concat(newGroups);
};

/**
 * Returns the AppNav object and its helper methods.
 */
export const useAppNav = () => {
  return {
    navigationItems: readonly(navigationItems.value[NAV_DEFAULT]),
    userNavigationItems: readonly(navigationItems.value[NAV_USER]),
    applicationTitleComponent: computed(() => applicationTitleComponent.value),
    areFavoritesEnabled: readonly(areFavoritesEnabled),
    getNavigationItems,
    setApplicationTitleComponent,
    setNavigationItems,
    setUserNavigationItems,
    addUsesrNavigationItems,
    homeRouteName: readonly(homeRouteName),
    setHomeRouteName,
  };
};
