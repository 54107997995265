<template>
  <template v-if="hasNoValue || parsedList.length === 0">—</template
  ><template v-for="(item, index) in parsedList" :key="item[0]"
    >{{ index > 0 ? ", " : "" }}{{ item[0] }}{{ item[1] ?? "" }}<wbr v-if="item[2]" />{{ item[2] ?? "" }}</template
  >
</template>

<script lang="ts">
import { ensureArray } from "@/utils/helpers/ensureArray";
import { PropType, computed, defineComponent } from "vue";
/** Component to control the placement of an email's word break to be at the "@" symbol. Prevents email from being cut off in a weird place */
export default defineComponent({
  name: "EmailWbr",
  components: {},
  props: {
    /** Email to be displayed */
    val: {
      type: null as unknown as PropType<string | Array<string> | null | undefined>,
      default: undefined,
      required: true,
    },
  },
  setup(props, context) {
    const hasNoValue = computed(() => {
      return (
        props.val === null ||
        props.val === undefined ||
        props.val === "" ||
        (Array.isArray(props.val) && props.val?.length === 0)
      );
    });

    const parsedList = computed(() => {
      if (hasNoValue.value) return [];

      let result = ensureArray(props.val);
      result = (result as Array<string>).filter((x) => x);
      return (result as Array<string>).map((x) => x.split(/(@)/));
    });

    return { hasNoValue, parsedList };
  },
});
</script>

<style module></style>
