import { useAppSettingsStore } from "@/components/common/appSettingsStore.js";
import type { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { APP_VERSION, CACHE_METADATA, PRODUCT_VERSION } from "./constants/headers";

/** Everything in here is meant to be internal to apiService and not exposed via the library */

interface CacheHeaders {
  [APP_VERSION]: string;
  [PRODUCT_VERSION]: string;
  metadata: CacheMetadata | null;
}

interface CacheMetadata {
  timestamp: number;
}

function isCacheUnsupported() {
  return typeof caches === "undefined";
}

function getCacheName(apiHost?: string) {
  const appSettingsStore = useAppSettingsStore();
  return appSettingsStore.systemIdentifier + "_" + apiHost;
}

function getUrlFromConfig(config: InternalAxiosRequestConfig) {
  // If we're missing URL information, bypass caching.
  if (!config.baseURL || !config.url) throw new Error("bad config");

  let url = config.baseURL;
  url += url?.endsWith("/") ? "" : "/";
  url += config.url;
  return url;
}

// Axios request interceptor to error on requests that are cached (if they're cached)
export function getAxiosRequestCachingInterceptor(apiHost?: string) {
  const cacheName = getCacheName(apiHost);

  return async (config: InternalAxiosRequestConfig) => {
    // If cache is unsupported or we're trying to do something other than a get, bypass caching.
    if (isCacheUnsupported() || config.method !== "get") return config;

    let url = "";
    try {
      url = getUrlFromConfig(config);
    } catch (error) {
      return config;
    }

    const request = new Request(url, {
      method: "GET",
      headers: config.headers as HeadersInit,
    });

    try {
      const cachedResponse = await getCachedResponse(cacheName, request);
      if (cachedResponse) {
        const data = await fetchResponseToJson(cachedResponse);
        // Throw special error to cancel request and use cached data
        throw { config, cachedData: data };
      }
    } catch (error) {
      if ("cachedData" in (error as any)) {
        throw error;
      }
      // If error reading cache, proceed with network request
      console.error("Error reading from cache:", error);
    }

    return config;
  };
}

// Catches Axios errors having cached data and returns that cached data. (In response to the getAxiosRequestCachingInterceptor)
export function axiosResponseCachingErrorInterceptor(error: any) {
  if (error.config && "cachedData" in error) {
    // Return cached data as successful response
    return Promise.resolve({
      ...error.config,
      data: error.cachedData,
      status: 200,
      statusText: "OK (Cached)",
    });
  }
  return Promise.reject(error);
}

// Axios response interceptor to cache successful responses
export function getAxiosResponseCachingInterceptor(apiHost?: string) {
  const cacheName = getCacheName(apiHost);

  return async (response: AxiosResponse<any, any>) => {
    if (isCacheUnsupported() || response.config.method !== "get") {
      return response;
    }

    let url = "";
    try {
      url = getUrlFromConfig(response.config);
    } catch (error) {
      return response;
    }

    const request = new Request(url, {
      method: "GET",
      headers: response.config.headers as HeadersInit,
    });

    try {
      const fetchResponse = await axiosResponseToFetchResponse(response);
      await cacheResponse(cacheName, request, fetchResponse);
    } catch (error) {
      console.error("Error caching response:", error);
    }

    return response;
  };
}

// Parses response headers for use in determining cache validity.
async function getResponseVersionAndMetadata(response: Response): Promise<CacheHeaders | null> {
  try {
    const metadata = response.headers.get(CACHE_METADATA);
    const headers = {
      [APP_VERSION]: response.headers.get(APP_VERSION) ?? "",
      [PRODUCT_VERSION]: response.headers.get(PRODUCT_VERSION) ?? "",
      metadata: metadata ? (JSON.parse(metadata) as CacheMetadata) : null,
    };

    if (headers[APP_VERSION] === null) throw Error("No APP_VERSION specified.");

    return headers;
  } catch (error) {
    console.error("Failed to parse cache metadata:", error);
    return null;
  }
}

export async function getCacheInstance(cacheName: string): Promise<Cache | null> {
  if (typeof caches === "undefined") return null;

  try {
    return await caches.open(cacheName);
  } catch (error) {
    console.error("Failed to open cache:", error);
    return null;
  }
}

// Attempts to pull data for a request from the cache. If the cached data is expired it will be deleted and null will be returned.
export async function getCachedResponse(cacheName: string, request: Request): Promise<Response | null> {
  const cache = await getCacheInstance(cacheName);
  if (!cache) return null;

  try {
    const response = await cache.match(request);
    if (!response) return null;

    const appSettingsStore = useAppSettingsStore();
    const headers = await getResponseVersionAndMetadata(response);

    // Wipe the entire cache for an app version mismatch.
    if (headers?.[APP_VERSION] !== appSettingsStore.appVersion) {
      await caches.delete(cacheName);
      return null;
    }

    // Clear the cache for items with expired or no timestamp.
    const isExpired = headers?.metadata?.timestamp
      ? headers.metadata.timestamp + appSettingsStore.cacheTtl < Date.now()
      : true;
    if (isExpired) {
      await cache.delete(request);
      return null;
    }

    return response;
  } catch (error) {
    console.error("Failed to get cached response:", error);
    return null;
  }
}

export async function cacheResponse(cacheName: string, request: Request, response: Response): Promise<void> {
  const cache = await getCacheInstance(cacheName);
  if (!cache) return;

  try {
    const responseToCache = await createResponseWithMetadata(response);
    await cache.put(request, responseToCache);
  } catch (error) {
    console.error("Failed to cache response:", error);
  }
}

async function createResponseWithMetadata(response: Response): Promise<Response> {
  const metadata: CacheMetadata = {
    timestamp: Date.now(),
  };

  const responseClone = response.clone();
  const body = await responseClone.blob();

  return new Response(body, {
    status: response.status,
    statusText: response.statusText,
    headers: new Headers({
      /* @ts-expect-error - this is valid; TS types are wrong - https://developer.mozilla.org/en-US/docs/Web/API/Headers/entries  */
      ...Object.fromEntries(response.headers.entries()),
      [CACHE_METADATA]: JSON.stringify(metadata),
    }),
  });
}

// Converts an Axios response to a standard Response object.
export async function axiosResponseToFetchResponse(axiosResponse: AxiosResponse): Promise<Response> {
  const blob = new Blob([JSON.stringify(axiosResponse.data)], {
    type: "application/json",
  });

  return new Response(blob, {
    status: axiosResponse.status,
    statusText: axiosResponse.statusText,
    headers: new Headers(axiosResponse.headers as any),
  });
}

export async function fetchResponseToJson<T>(response: Response): Promise<T> {
  const text = await response.text();
  return JSON.parse(text);
}
